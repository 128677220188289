import React from 'react';

const WorkItems = ({ item }) => {
  return (
    <div
      className='work__card'
      key={item.id}
    >
      <img
        src={item.image}
        alt='item-img'
        className='work__img'
      />
      <h3 className='work__title'>{item.title}</h3>
      <p className='work_descritpion'>{item.description}</p>
      <a
        href={item.link}
        target='websites'
        className='work__button'
      >
        Demo <i className='bx bx-right-arrow-alt work__button-icon'></i>
      </a>
    </div>
  );
};

export default WorkItems;
